import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Bienvenue chez Gallic
			</title>
			<meta name={"description"} content={"Revitalisez votre véhicule - Gallic, votre partenaire de confiance sur la route\n"} />
			<meta property={"og:title"} content={"Bienvenue chez Gallic"} />
			<meta property={"og:description"} content={"Revitalisez votre véhicule - Gallic, votre partenaire de confiance sur la route\n"} />
			<meta property={"og:image"} content={"https://gallic.live/media/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://gallic.live/media/523523522535.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://gallic.live/media/523523522535.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://gallic.live/media/523523522535.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://gallic.live/media/523523522535.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://gallic.live/media/523523522535.png"} />
			<meta name={"msapplication-TileImage"} content={"https://gallic.live/media/523523522535.png"} />
		</Helmet>
		<Components.Header />
		<Section background="--color-primary" padding="80px 0 80px 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline1"
					color="--light"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Contact
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--light" text-align="center">
					Pour toute demande de renseignements ou de rendez-vous, appelez-nous ou envoyez-nous un courriel.
				</Text>
				<Link
					href="mailto:blank?contact@gallic.live"
					text-align="center"
					color="--light"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					contact@gallic.live
				</Link>
				<Link
					href="tel:+33 6 68 96 26 28"
					text-align="center"
					color="--light"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					+33 6 68 96 26 28
				</Link>
				<Text margin="0px 0px 48px 0px" font="--headline3" color="--light" text-align="center">
					4 Av. Pierre Lizart, 95400 Arnouville, France
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});